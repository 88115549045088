<template>
  <div
    v-for="(item, num) in state.faqList"
    :key="num"
    class="faq-block-item border-t"
    :class="item.active ? 'is-active' : ''"
  >
    <div class="heading-question">
      <p class="heading-question-text">
        {{ item.q }}
      </p>
      <p @click="openAnswer(num)" class="heading-question-button"></p>
    </div>
    <div v-if="item.active" class="body-answer">
      <p class="body-answer-text" v-html="item.a"></p>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "faqList",
  components: {},
  props: ["faq"],
  setup(props) {
    const state = reactive({
      faqList: props.faq,
    });
    const openAnswer = (num) => {
      state.faqList[num].active = !state.faqList[num].active;
    };
    return {
      state,
      openAnswer,
    };
  },
});
</script>

<style lang="scss" scoped>
.faq-block-item {
  position: relative;
  margin-top: 0;
  padding: 60px 0 60px 100px;
  &::after {
    content: "";
    width: 30px;
    height: 4px;
    position: absolute;
    top: -2px;
    left: 0;
    background-color: $black;
    z-index: 1;
  }
  @include mq(sm) {
    padding: 75px 0 45px;
  }
  &:nth-of-type(2n) {
    position: relative;
    &:before {
      content: "";
      width: calc(100% + 130px);
      height: 100%;
      position: absolute;
      top: 50%;
      left: -65px;
      background-color: #f8fbfb;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
  &:last-of-type {
    border-bottom: 1px solid $black;
  }
  // ▽アクティブ時
  &.is-active {
    .heading-question {
      &:after {
        font-size: 50px;
        @include mq(sm) {
          top: -1.25em;
          font-size: 30px;
        }
      }
      .heading-question-button {
        @include mq(sm) {
          margin-top: -14px;
        }
      }
    }
    .heading-question-button {
      background-color: #3683f5;
      box-shadow: 0px 20px 30px rgba(54, 131, 245, 0.25);

      &:before {
        content: none;
      }
      &::after {
        background-color: #ffffff;
      }
    }
  }
}
// △アクティブ時

.heading-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &::after {
    content: "Q.";
    position: absolute;
    top: 50%;
    left: -100px;
    font-size: 26px;
    transform: translate(0, -50%);
    @include mq(sm) {
      top: -1.5em;
      left: 0;
      font-size: 20px;
      transform: translate(0, -50%);
    }
  }
}
.heading-question-text {
  font-size: 26px;
  font-weight: bold;
  @include mq(sm) {
    font-size: 20px;
  }
}
.heading-question-button {
  position: relative;
  width: 50px;
  height: 50px;

  cursor: pointer;
  @include mq(sm) {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -1.5em;
    right: 0;
    transform: translate(0, -50%);
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20px;
    width: 1px;
    background-color: $black;
    transform: translateY(-50%);
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 20px;
    background-color: $black;
    transform: translateX(-50%);
  }
}
.body-answer {
  margin-top: 30px;
}
.body-answer-text {
  font-size: 14px;
}</style
>>
