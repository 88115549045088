<template>
  <main class="faq">
    <content-page-header
      ><common-page-title title="FAQ’s" />
      <div class="page-header-explanation">
        <p class="page-header-explanation-text">
          ジッセン!DXのコンテンツは、使用可能なインターネット接続されたデバイス (スマートフォンおよびタブレット) を通じて視聴可能です。<br />
          お使いのコンピュータからインターネットブラウザを使用して視聴することも可能です。<br />
          ウェブブラウザの互換性についてはシステム要件をご確認ください。<br />
          また、社内教育用のシステム、連携、活用などはお問い合わせよりご連絡ください。
        </p>
      </div>
    </content-page-header>
    <div class="content-body">
      <div>
        <tab tab-count="5" :tab-name="state.tabName" @tabClick="tabClick" />
      </div>
      <panel-body>
        <section v-if="state.tabBody === 0">
          <common-block-title title="お支払い関連" sub-title="" />
          <div class="faq-block">
            <faq-list :faq="state.faqSubscription" />
          </div>
        </section>
        <section v-if="state.tabBody === 1">
          <common-block-title title="会員ステータス関連" sub-title="" />
          <div class="faq-block"><faq-list :faq="state.faqAccount" /></div>
        </section>
        <section v-if="state.tabBody === 2">
          <common-block-title title="講座コンテンツ" sub-title="" />
          <div class="faq-block"><faq-list :faq="state.faqContent" /></div>
        </section>
        <section v-if="state.tabBody === 3">
          <common-block-title title="操作方法" sub-title="" />
          <div class="faq-block"><faq-list :faq="state.faqManual" /></div>
        </section>
        <section v-if="state.tabBody === 4">
          <common-block-title title="その他" sub-title="" />
          <div class="faq-block"><faq-list :faq="state.faqOthers" /></div>
        </section>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import Tab from "@/components/CommonTab/tab.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import faqList from "@/views/faq/components/faqList.vue";

export default defineComponent({
  name: "faq",
  components: {
    contentPageHeader,
    CommonPageTitle,
    CommonBlockTitle,
    Tab,
    PanelBody,
    faqList,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isEnterprise = computed(() => store.state.user.isEnterprise)

    const state = reactive({
      tabName: [
        {
          en: "SUBSCRIPTION",
          ja: "お支払い関連",
          isActive: true,
        },
        {
          en: "ACCOUNT",
          ja: "会員ステータス関連",
          isActive: false,
        },
        {
          en: "CONTENT",
          ja: "講座コンテンツ",
          isActive: false,
        },
        {
          en: "MANUAL",
          ja: "操作方法",
          isActive: false,
        },
        {
          en: "OTHERS",
          ja: "その他",
          isActive: false,
        },
      ],
      faqSubscription: [
        {
          q: "無料トライアルはいつから料金が発生しますか？",
          a:
            "初回登録完了日から7日経過したタイミングで、ご登録いただいたクレジットカードにて決済されます。",
          active: false,
        },
        {
          q: "プレミアム会員の場合、料金はどのように支払いますか？",
          a: "お支払いはクレジットカード決済のみで承っております。",
          active: false,
        },
        {
          q: "プレミアム会員です。いつ支払いが発生しますか？",
          a:
            "ご登録日と同じ日付で毎月料金が自動で請求され、サブスクリプションは課金対象期間の初日に請求されます。決済までに数日かかる場合があります。<br /><br />※時差により、請求日の日にちが1日早くなる場合があります。<br />※請求日の日にちが存在しない月がある場合 (31日など)、代わりに月末が請求日となります。<br />※お支払いがサードパーティ経由で行われる場合、請求日は、プロバイダーの請求日によって異なる場合があります。",
          active: false,
        },
        {
          q: "プレミアム会員の日割り料金や払い戻しはありますか？",
          a: "日割り料金はありません。",
          active: false,
        },
        {
          q: "クレジットカードで決済をしています。領収証の発行はできますか？",
          a:
            "画面右上のメニューを開いていただき、アカウント情報ページの右上にある支払いを選択し、「決済履歴」より発行が可能です。<br />尚、領収証は過去12ヶ月分のものを閲覧、ダウンロード可能です。",
          active: false,
        },
        {
          q: "支払い方法が拒否されて決済できませんでした。",
          a:
            "カード番号、セキュリティコード、有効期限などのお支払い情報が正しいことを確認していただき、再度お支払いの登録をしてください。<br />問題が解決しない場合は、指定したお支払い方法が電子商取引に対応していることを確認してください。",
          active: false,
        },
        {
          q: "思ったより早く請求されたのですが？",
          a:
            "予定されている請求日の日付がその月にない場合は (31日など)、その月の最終日が請求日になります。<br />お客様が請求日を変更した後、料金の変更または、お客様によるプラン変更が同じ月に行われた場合は、予想よりも早く請求されることがあります。",
          active: false,
        },
        {
          q: "無料トライアル期間中に解約した場合、費用は発生しますか？",
          a:
            "初回登録完了日から7日間以内に解約された場合、費用は発生いたしません。<br />なお、無料トライアル期間中に解約された場合、解約手続き完了した時点でサービスをご利用いただけませんので予めご了承ください",
          active: false,
        },
      ],
      faqAccount: [
        {
          q: "パスワードを忘れてしまいログインできません。",
          a:
            "ログイン画面にある「パスワードを忘れた方はこちら」からパスワードリセットのお手続きをお願いいたします。",
          active: false,
        },
        {
          q: "メールアドレス・パスワードは変更できますか？",
          a:
            "マイページのアカウント情報より変更することができます。<br />変更の際は、現在のパスワードと新パスワードを入力いただき、Saveボタンを押下ください。",
          active: false,
        },
        {
          q: "自分の会員種別はどこで確認できますか？",
          a: "マイページ左上の会員氏名の上に表示されております。",
          active: false,
        },
        {
          q: "法人契約で複数のアカウント契約はできますか？",
          a:
            "はい。詳しくは担当よりご案内いたしますので、まずはページ最下部にある「法人向けサービス」よりお気軽にお問い合わせください。",
          active: false,
        },
      ],
      faqContent: [
        {
          q: "eラーニング以外の研修サービスもありますか？",
          a:
            "はい、eラーニングの研修サービス以外にも、講師派遣型のオフライン研修「ジッセン! プログラム/Webinar」のサービスも提供しております。ニーズや目的に併せた最適な研修ソリューションをご提案差し上げます。",
          active: false,
        },
        {
          q: "動画や講座内のパワーポイントはダウンロード出来ますか？",
          a:
            "できません。ただし、一部の講座には動画視聴画面内に添付ファイルが付いておりますので、その添付ファイルに限り、ダウンロードが可能となります。",
          active: false,
        },
        {
          q: "新規の講座はどれぐらいのペースでリリースされますか？",
          a:
            "新規講座のリリースは不定期で行っておりますが、平均して月に2～6本の新着講座が公開されています。",
          active: false,
        },
        {
          q: "一つの講座は何分くらいですか？",
          a:
            "1講座＝約20～60分で、約10分のチャプターによって構成されています。",
          active: false,
        },
        {
          q: "メモ機能とはどのような機能ですか？",
          a:
            "視聴画面内でメモを入力・保存できます。動画レッスンの場合、動画再生がストップされメモ保存した時刻が記録されます。後からメモを見返すなど、自身の学びのスタイルに併せてご活用ください。",
          active: false,
        },
      ],
      faqManual: [
        {
          q: "スマートフォンでも利用できますか？",
          a:
            "はい。利用できます。ただし、動画のストリーミング配信により通信量の負荷がかかるため、利用環境にご注意ください。（Wi-Fi環境を推奨します。）",
          active: false,
        },
        {
          q: "学習したい講座を探すには、どのような検索方法がありますか？",
          a:
            "ジッセン!DXでは学習目的に合わせて設計された「コース」、テーマごとに分類された「講座」を一覧から検索可能です。また、フリーワード・講座ごとに設定されたキーワードを検索フォームから検索するなど様々な方法で学習講座を探すことができます。",
          active: false,
        },
        {
          q: "サービスの利用方法を教えて下さい。",
          a:
            "ユーザーマニュアルをご用意しております。ページ最下部の「ユーザーマニュアル」からご確認ください。",
          active: false,
        },
      ],
      faqOthers: [
        {
          q:
            "学習時間がなかなか作れないのですが、効率的な学習方法はありますか？",
          a:
            "効率的な学習を進めるためにいくつかの方法があります。<br />①学習したい講座の中でも興味のあるチャプターだけを抜き出して受講する。<br />②動画再生の速度を1.5倍速、2倍速にする機能をご活用する。（推奨は1.5倍速）<br />③調べたい事柄・テーマがあった際に辞書的にジッセン!DXを活用する。",
          active: false,
        },
        {
          q: "解約はどこからできますか？",
          a:
            "ログイン後、アカウント情報ページの下部にある「解約はこちら」からお手続きください。",
          active: false,
        },
      ],
      tabBody: 0,
    });

    window.scrollTo(0, 0);

    const tabClick = (n) => {
      state.tabName.forEach((e) => {
        e.isActive = false;
      });
      state.tabName[n].isActive = true;
      state.tabBody = n;
    };
    onMounted(async () => {
      if (isEnterprise.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        if (isEnterprise.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      state,
      tabClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.faq {
  .content-header {
    @include mq(sm) {
      height: auto;
    }
  }
}
.page-header-explanation {
  padding-bottom: 95px;
}
.content-body {
  position: relative;
  margin-top: -160px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(md) {
    margin-top: -400px;
  }
  @include mq(sm) {
    margin-top: -95px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.faq-block {
  margin-top: 65px;
}</style
>>
